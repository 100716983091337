import React from "react";
import Header from "../components/Layouts/Header";
import Footer from "../components/Layouts/Footer";
import CoursesComponent from "../components/Courses/Courses";

const Courses = () => {
  return (
    <>
      <Header />
      <CoursesComponent />
      <Footer />
    </>
  );
};

export default Courses;
