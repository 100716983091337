import React from "react";
import { Route, Switch } from "react-router-dom";

import Landing from "./views/index";
import Programs from "./views/programs";
import Initiatives from "./views/initiatives";
import About from "./views/about";
import Contact from "./views/contact";
import Thankyou from "./views/thankyou";
import FAQs from "./views/faq";
import Donate from "./views/donate";
import Courses from "./views/courses";
import NotFound from "./views/404";

const app = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Landing />
      </Route>

      <Route path="/programs" exact>
        <Programs />
      </Route>

      <Route path="/initiatives" exact>
        <Initiatives />
      </Route>

      <Route path="/about" exact>
        <About />
      </Route>

      <Route path="/contact" exact>
        <Contact />
      </Route>

      <Route path="/thank-you" exact>
        <Thankyou />
      </Route>

      <Route path="/faqs" exact>
        <FAQs />
      </Route>

      <Route path="/donate" exact>
        <Donate />
      </Route>

      <Route path="/courses" exact>
        <Courses />
      </Route>

      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default app;
