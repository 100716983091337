import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Layouts/Header";
import Footer from "../components/Layouts/Footer";

const ThankYou = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="thank-you-area">
        <div className="container">
          <h3 className="pb-3">
            Thank you so much!!!! We'are very happy for you to support us. Your
            donation is going to help us a lot! and remember if you need to
            learn IT related stuff then <Link to="/programs">Start today.</Link>{" "}
            You're already our learner? that's cool!
          </h3>
          <Link to="/programs">
            <button className="btn coder-btn-outlined">
              Take a look a again at our programs
            </button>
          </Link>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default ThankYou;
