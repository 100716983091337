import React, { useEffect } from "react";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPrograms } from "../../redux/actions/program.action";

const ProgramsContent = () => {
  const dispatch = useDispatch();
  const { programs, loading } = useSelector((state) => state.programs);

  useEffect(() => {
    dispatch(getPrograms());
  }, [dispatch]);

  return (
    <section className="services-area-two ptb-150 bg-f7fafd">
      <div className="container">
        <div className="section-title">
          <h2 className="pb-3">All of our programs</h2>
          <p>
            We have got you age-based and profession-based programs. Send us an
            email for the course you would like to learn
          </p>
        </div>

        <div className="row">
          {!loading ? (
            programs.map((program) => {
              return (
                <div className="col-lg-4 col-md-6" key={program.id}>
                  <div className="single-services-box">
                    <h3>
                      <a href="mailto:coder.africa@gmail.com">
                        {program.p_name}: {program.p_participants}
                      </a>
                    </h3>
                    <p>{program.p_details}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-lg-4 col-md-6">
              <div className="single-services-box">
                <Spinner size="sm" color="primary" />
              </div>
            </div>
          )}

          <div className="col-lg-4 col-md-6">
            <section className="ready-to-talk p-4">
              <div className="container">
                <h3>Start learning today.</h3>
                <p>
                  Choose what you would like to learn and let us upskill you
                </p>

                <span>
                  <Link className="pt-2" to="/contact">
                    Or, contact us
                  </Link>
                </span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProgramsContent;
