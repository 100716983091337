import React from "react";

const Courses = () => {
  return (
    <section className="project-details-area ptb-150 bg-f7fafd">
      <div className="container">
        <div className="section-title">
          {/* <h2 className="pb-2">Watch all courses</h2> */}
          <h2 className="pb-2">
            No uploaded content yet. please{" "}
            <a href="mailto:coder.africa@gmail.com">click here</a> to email us,
            we will send you content via email right away.
          </h2>
          {/* <p>
            Our learners take their courses by using video communications
            platforms such as zoom ( zoom is the leader in modern enterprise
            video communications, with an easy, reliable cloud platform for
            video and audio conferencing, chat, and webinars), after every class
            we upload videos here so that you can watch them later.
          </p> */}
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="course-poster">{/* video goes here */}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Courses;
