import React from "react";
import { Link } from "react-router-dom";
import Typical from "react-typical";

const steps = ["It's completely free!", 1000, "It's completely fun! 👌", 1000];

const Partner = () => {
  return (
    <section className="ready-to-talk">
      <div className="container">
        <h3>Join the thousands Learning computer technologies with us</h3>
        <p>Start learnig today.</p>
        <Typical wrapper="p" steps={steps} loop={Infinity} />
        <Link to="/programs">
          <button className="btn coder-btn-outlined-2">Get started</button>
        </Link>
        <span>
          <Link to="/contact">Or, contact us if you have any question</Link>
        </span>
      </div>
    </section>
  );
};

export default Partner;
