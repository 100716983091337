import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCvgwbJBsLx3NW-do5n3QX-H_i7tgAf9S0",
  authDomain: "coder-ace9a.firebaseapp.com",
  projectId: "coder-ace9a",
  storageBucket: "coder-ace9a.appspot.com",
  messagingSenderId: "112004678602",
  appId: "1:112004678602:web:85771957f6f5b5316dc533",
  measurementId: "G-XX0E0XW9MJ",
};

const app = firebase.initializeApp(firebaseConfig);
export const db = app.firestore();
