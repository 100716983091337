import React from "react";
import Header from "../components/Layouts/Header";
import MainBanner from "../components/home/MainBanner";
import ServicesArea from "../components/home/ServicesArea";
import Funfacts from "../components/home/Funfacts";
import Initiatives from "../components/home/Initiatives";
import BannerNotifier from "../components/home/BannerNotifier";
import Footer from "../components/Layouts/Footer";

const Index = () => {
  return (
    <>
      <Header />
      <MainBanner />
      <ServicesArea />
      <Funfacts />
      <Initiatives />
      <BannerNotifier />
      <Footer />
    </>
  );
};
export default Index;
