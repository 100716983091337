import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import "isomorphic-fetch";
import { Link } from "react-router-dom";

const ContentBody = () => {
  return (
    <section className="faq-area ptb-150">
      <div className="container">
        <div className="faq-accordion">
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>What is the cost?</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  For any inquiries about our prices, please write to{" "}
                  <a href="mailto:coder.africa@gmail.com">
                    coder.africa@gmail.com
                  </a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  How can I donate and why?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Today’s world greatly depends on IT. Our future’s greatness
                  will be determined by the continuous development of infotech.
                  We provide capacity building that aims at assisting anyone who
                  wishes to gain more knowledge in the field. As we provide
                  these services at no cost, we highly welcome and appreciate
                  donations. Any contribution towards our project, no matter how
                  small, goes a long way. You can donate by following this{" "}
                  <Link to="/donate">link</Link>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  How can I get in touch with you?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  You can contact us by following this{" "}
                  <Link to="/contact">link</Link>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What will I be able to do upon completing your courses?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Upon completion of our courses you will be knowledgeable in
                  computer programming, computer hardware and/or computer
                  networking depending on the course you chose to follow. You
                  will have learned the latest frameworks that include React,
                  NativeScript and many more. You will be fully qualified to
                  pursue a career in web and mobile app development as well as
                  all IT related jobs.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What are the requirements to start learning with you?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  You will only need to pick your preferred course and send us
                  an email requesting to learn that course. As simple as that!
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default ContentBody;
