import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronDown, CheckCircle } from "react-feather";
import logo from "../../assets/img/logo.png";

const Header = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  }, []);

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <header id="header" className="navbar-style-three">
      <div id="navbar" className={`coder-nav`}>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-md navbar-light">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="" />
            </Link>
            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/programs">
                    Programs
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/courses">
                    Courses
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/initiatives">
                    Initiatives
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <a href="/" className="nav-link">
                    More <ChevronDown />
                  </a>
                  <ul className="dropdown_menu">
                    <li className="nav-item">
                      <a
                        href="https://amgroup.africa/service/Offshore%20Outsourcing"
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircle /> Offshore Outsourcing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://amgroup.africa/service/Innovation,%20Startup%20&%20Entrepreneurship"
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircle /> Innovation, Startup & Entrepreneurship
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://amgroup.africa/service/Investment"
                        className="nav-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CheckCircle /> Investment
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="others-option">
              <Link to="/" className="btn coder-btn-outlined">
                Login with Google
              </Link>
              <Link to="/donate" className="btn coder-btn">
                Donate
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
