import React from "react";
import { Link } from "react-router-dom";

const Custom404 = () => {
  return (
    <section className="error-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="error-content">
              <div className="notfound-404">
                <h1>Oops!</h1>
              </div>
              <h3>404 - Page Was Not Found</h3>
              <Link className="btn coder-btn-outlined" to="/">
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Custom404;
