import React from "react";
import Header from "../components/Layouts/Header";
import DonationsComponent from "../components/donationsComponent/Donations";

const Donate = () => {
  return (
    <React.Fragment>
      <Header />
      <DonationsComponent />
    </React.Fragment>
  );
};

export default Donate;
