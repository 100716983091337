import React from "react";
import Header from "../components/Layouts/Header";
import BannerNotifier from "../components/home/BannerNotifier";
import Footer from "../components/Layouts/Footer";
import InitiativesComponent from "../components/initiatives/Initiatives";

const Initiatives = () => {
  return (
    <>
      <Header />
      <InitiativesComponent />
      <BannerNotifier />
      <Footer />
    </>
  );
};

export default Initiatives;
