import React from "react";
import Header from "../components/Layouts/Header";
import Funfacts from "../components/home/Funfacts";
import BannerNotifier from "../components/home/BannerNotifier";
import Footer from "../components/Layouts/Footer";
import ContentBody from "../components/faq/ContentBody";

const Faq = () => {
  return (
    <>
      <Header />
      <ContentBody />
      <Funfacts />
      <BannerNotifier />
      <Footer />
    </>
  );
};

export default Faq;
