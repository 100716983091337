import { db } from "../../config/firebase";
import { programsActionTypes } from "../actionTypes";

export const getPrograms = () => async (dispatch) => {
  try {
    dispatch({
      type: programsActionTypes.PROGRAMS_REQUEST,
    });

    const snapshot = await db.collection("programs").get();
    const data = snapshot.docs.map((doc) => doc.data());

    dispatch({
      type: programsActionTypes.PROGRAMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: programsActionTypes.PROGRAMS_FAIL,
      payload: error,
    });
  }
};
