import React from "react";
import Header from "../components/Layouts/Header";
import Funfacts from "../components/home/Funfacts";
import BannerNotifier from "../components/home/BannerNotifier";
import Footer from "../components/Layouts/Footer";
import ContactBody from "../components/contact/ContactBody";

const Contact = () => {
  return (
    <>
      <Header />
      <ContactBody />
      <Funfacts />
      <BannerNotifier />
      <Footer />
    </>
  );
};

export default Contact;
