import React from "react";
import OwlCarousel from "react-owl-carousel3";
import { Link } from "react-router-dom";

const slideOptions = {
  items: 2,
  loop: true,
  nav: false,
  autoplay: true,
  dots: false,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 2,
    },
    1500: {
      items: 2,
    },
  },
};

const Initiatives = () => {
  return (
    <section className="initiative-area ptb-80 bg-f7fafd">
      <div className="container">
        <div className="section-title">
          <h2 className="pb-3">Initiatives</h2>
          <p>
            In FUTURE CODERS program, students of 8-15 years old are introduced
            in the fundamentals of coding. And SCHOOL OF CODING, provide a
            unique, extensive program that will help equip and groom talented
            creative refugee programmers.
          </p>
        </div>
      </div>

      <div className="container">
        <div className="row m-0">
          <OwlCarousel
            className="initiative-slides owl-carousel owl-theme"
            {...slideOptions}
          >
            <div className="item">
              <div className="col-lg-12">
                <div className="single-initiative">
                  <h3>
                    <a
                      href="https://amgroup.africa/initiative/SCHOOL%20OF%20CODING"
                      target="_blank"
                      rel="noreferrer"
                    >
                      SCHOOL OF CODING
                    </a>
                  </h3>
                  <p>
                    In partnership with the American Refugee Committee, we have
                    designed The School of Coding with the aim to provide a
                    unique, extensive program that will help equip and groom
                    talented, creative refugee programmers. We give them the
                    necessary tools to prepare for an outstanding future as
                    programmers and developers in Rwanda.
                  </p>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="col-lg-12">
                <div className="single-initiative">
                  <h3>
                    <Link to="/initiatives">
                      DIGITAL TRAINING FOR YOUNG GIRLS
                    </Link>
                  </h3>
                  <p>
                    A program that gives opportunities to young women by
                    offering digital training to adolescent girls and connecting
                    them to internships where they get to develop digital apps
                    to address market barriers.
                  </p>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="col-lg-12">
                <div className="single-initiative">
                  <h3>
                    <a
                      href="https://amgroup.africa/initiative/FUTURE%20CODERS"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FUTURE CODERS
                    </a>
                  </h3>
                  <p>
                    This is an extracurricular program, where students aged 8-15
                    years old are introduced to the fundamentals of coding.
                    Along with learning programming and how to code, these
                    students are also taught how to put the acquire skills into
                    practice.
                  </p>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default Initiatives;
