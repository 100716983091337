import React from "react";
import { ThumbsUp } from "react-feather";
import Typical from "react-typical";

const steps = ["It's completely free!", 1000, "It's completely fun! 👌", 1000];

const Funfacts = () => {
  return (
    <section className="funfacts-area ptb-80">
      <div className="container">
        <div className="section-title">
          <h2 className="pb-3">
            Fun Facts <ThumbsUp />
          </h2>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-3 col-6">
            <div className="funfact">
              <h3>100%</h3>
              <p>Helpful</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-6">
            <div className="funfact">
              <h3>100%</h3>
              <p>Fun</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-6">
            <div className="funfact">
              <h3>450</h3>
              <p>Learners</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-6">
            <div className="funfact">
              <h3>3</h3>
              <p>Initiatives</p>
            </div>
          </div>
        </div>

        <div className="contact-cta-box">
          <h3>Not familiar with Information Technology?</h3>
          <p>
            No need to worry, we will teach you about it all. All you need to
            get started is to send us an email containing what you would like to
            learn. <i className="fa fa-hand-o-right" aria-hidden="true"></i>
          </p>
          <Typical wrapper="p" steps={steps} loop={Infinity} />
          <a href="mailto:coder.africa@gmail.com">
            <button className="btn coder-btn-outlined">Email us</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Funfacts;
