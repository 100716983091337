import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Check, CheckCircle, Code, Globe, DollarSign } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { getPrograms } from "../../redux/actions/program.action";

const ServicesArea = () => {
  const dispatch = useDispatch();
  const { programs, loading } = useSelector((state) => state.programs);

  useEffect(() => {
    dispatch(getPrograms());
  }, [dispatch]);

  return (
    <React.Fragment>
      <section className="services-area coder--services-top ptb-80 bg-f7fafd">
        <div className="container">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 services-content">
              <div className="section-title">
                <h4>
                  We brought to you both age-based and profession-based
                  programs.{" "}
                  <span className="text-muted">
                    Send us an email for what you would like to learn
                  </span>
                </h4>
                <h5 className="pt-3 pb-3">
                  <Check /> Our age-based programs
                </h5>
                <p>
                  We teach the younger generations various technological skills
                  and programming languages. With us, children aged 6-9 learn
                  about{" "}
                  <a
                    href="https://scratch.mit.edu/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Scratch programming
                  </a>
                  . 10 to 12 years old are to be introduced to{" "}
                  <a href="https://html.com/" target="_blank" rel="noreferrer">
                    HTML
                  </a>
                  , as well as{" "}
                  <a
                    href="https://www.w3.org/Style/CSS/Overview.en.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    CSS
                  </a>
                  . Teenagers from 13 to 15 years old pick up from their juniors
                  with more advanced HTML and CSS courses, coupled with{" "}
                  <a
                    href="https://www.javascript.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    JavaScript
                  </a>{" "}
                  lessons.
                </p>
              </div>

              <div className="row">
                {!loading ? (
                  programs.map((program) => {
                    if (program.p_type === "Age-based")
                      return (
                        <div
                          key={program.id}
                          className="col-lg-6 col-md-6 like-cursor"
                        >
                          <a href="mailto:coder.africa@gmail.com">
                            <div className="box">
                              <CheckCircle /> {program.p_participants}:{" "}
                              {program.p_name}.{" "}
                              {program.p_name !==
                              "Intro to Linux and Networking, JavaScript, Java, and Python"
                                ? "Click to register in this course"
                                : "Get start"}
                            </div>
                          </a>
                        </div>
                      );
                    return null;
                  })
                ) : (
                  <div className="col-lg-6 col-md-6">
                    <div className="box">
                      <Spinner size="sm" color="primary" />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-6 col-md-12 services-right-image"></div>
          </div>
        </div>
      </section>

      <section className="services-area coder--services-bottom ptb-80">
        <div className="container">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 services-left-image"></div>

            <div className="col-lg-6 col-md-12 services-content">
              <div className="section-title">
                <h5 className="pt-3 pb-3">
                  <Check /> Profession-based programs
                </h5>
                <p>
                  We offer our services to both IT professionals and
                  non-professionals. We equip them with the skills required in
                  their working environment.
                </p>
              </div>

              <div className="row">
                {!loading ? (
                  programs.map((program) => {
                    if (program.p_type === "Profession-based") {
                      return (
                        <div
                          key={program.id}
                          className="col-lg-6 col-md-6 like-cursor"
                        >
                          <a href="mailto:coder.africa@gmail.com">
                            <div className="box">
                              <CheckCircle /> We upskill professionals in{" "}
                              {program.p_name}.{" "}
                              {program.p_name !== "JavaScript, Java, and Python"
                                ? "Click to get started"
                                : null}
                            </div>
                          </a>
                        </div>
                      );
                    }
                    return null;
                  })
                ) : (
                  <div className="col-lg-6 col-md-6">
                    <div className="box">
                      <Spinner size="sm" color="primary" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features-area ptb-80 bg-transparent">
        <div className="container">
          <div className="section-title">
            <h2 className="pb-3 white-text">There is More!</h2>
            <p className="white-text">
              We are not limited to computing and programming trainings. We have
              more services to offer.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 like-cursor">
              <a
                href="https://amgroup.africa/service/Offshore%20Outsourcing"
                target="_blank"
                rel="noreferrer"
              >
                <div className="single-features">
                  <div className="icon">
                    <Code />
                  </div>

                  <h3>Offshore Outsourcing</h3>
                  <p>
                    This is a product namely Test Africa mainly focused on
                    mobile and web app development, custom software development,
                    front-end and back-end development, flutter, react native,
                    and many more... Learn more
                  </p>
                </div>
              </a>
            </div>

            <div className="col-lg-6 col-md-6 like-cursor">
              <a
                href="https://amgroup.africa/service/Innovation,%20Startup%20&%20Entrepreneurship"
                target="_blank"
                rel="noreferrer"
              >
                <div className="single-features">
                  <div className="icon">
                    <Globe />
                  </div>

                  <h3>Innovation, Start-ups & Entrepreneurship</h3>
                  <p>
                    This is a customized entrepreneurship ecosystem support
                    program whose main goal is attracting start-ups, investment
                    funds, and academia to scale up companies in various African
                    countries... Learn more{" "}
                  </p>
                </div>
              </a>
            </div>

            <div className="col-lg-6 col-md-6 like-cursor">
              <a
                href="https://amgroup.africa/service/Investment"
                target="_blank"
                rel="noreferrer"
              >
                <div className="single-features">
                  <div className="icon">
                    <DollarSign />
                  </div>

                  <h3>Investment</h3>
                  <p>
                    This program aims at connecting investors or donors to
                    institutions, organizations, companies, startups or
                    individuals seeking for investments, loans, or donations.
                    This service primarily targets governments, banks,... Learn
                    more{" "}
                  </p>
                </div>
              </a>
            </div>

            <div className="col-lg-6 col-md-6">
              <section className="ready-to-talk p-4">
                <div className="container">
                  <h3>Interested? Talk To Us.</h3>
                  <p>
                    If you are interested, kindly reach out by sending us a
                    message through the link below.
                  </p>
                  <Link to="/contact">
                    <button className="btn coder-btn-outlined-2">
                      Contact Us
                    </button>
                  </Link>
                  <span>
                    <a
                      href="https://amgroup.africa/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Follow this link to learn more about our services.
                    </a>
                  </span>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ServicesArea;
