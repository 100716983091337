import React, { useState } from "react";
import { FlutterWaveButton } from "flutterwave-react-v3";
import logo from "../../assets/img/logo.png";

const DonationsComponent = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userNames, setUserNames] = useState("");
  const config = {
    public_key: "FLWPUBK-ed0d80c843f59272735b2a28f7c6458c-X",
    tx_ref: Date.now(),
    amount: "",
    currency: "USD",
    payment_options: "card,mobilemoney,account,bank transfer,ussd",
    redirect_url: "https://coder.africa/thank-you",
    customer: {
      email: userEmail,
      name: userNames,
    },
    customizations: {
      title: "Coder Africa",
      description: "Help us to keep it up!",
      logo: logo,
    },
  };
  const fwConfig = {
    ...config,
    text: "Continue",
    callback: (response) => {
      //   console.log(response);
    },
    onClose: () => {},
  };

  const handleContinue = (e) => {
    e.preventDefault();
  };
  return (
    <div className="main-banner bg-f7fafd">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-5">
                <div className="hero-content">
                  <h2>Help us progress!</h2>
                  <p>
                    Today’s world greatly depends on IT. Our future’s greatness
                    will be determined by the continuous development of
                    infotech. We provide capacity building that aims at
                    assisting anyone who wishes to gain more knowledge in the
                    field. If you believe in this cause, kindly fill out your
                    names and e-mail, and donate to this program.
                  </p>
                </div>
              </div>

              <div className="col-lg-5 offset-lg-1">
                <div className="banner-form ml-3">
                  <form onSubmit={(e) => handleContinue(e)} noValidate>
                    <div className="form-group">
                      <label>Names</label>
                      <input
                        type="text"
                        name="names"
                        id="names"
                        className="form-control"
                        placeholder="Your names"
                        onChange={(e) => setUserNames(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Your email"
                        onChange={(e) => setUserEmail(e.target.value)}
                        required
                      />
                    </div>
                    <FlutterWaveButton
                      {...fwConfig}
                      className="btn coder-btn-outlined"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DonationsComponent;
