import React from "react";
import "isomorphic-fetch";

const ContactBody = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <section className="contact-area ptb-150 bg-f7fafd">
      <div className="container">
        <div className="section-title">
          <h2 className="pb-3">Get In Touch With Us</h2>
          <p>
            If you have any question, we are happy to help! Please fill in your
            contact information and question below. We will get back at you as
            soon as possible. You can also chat with us by clicking at the
            thumbnail on the bottom right corner.
          </p>

          <div className="row justify-content-center align-items-center ">
            <div className="col-lg-6 col-md-12 col-12">
              <div class="alert alert-warning mt-5" role="alert">
                This form below is under mantainance, please send us an email or
                chat with us by clicking at the thumbnail on the bottom right
                corner.
              </div>
            </div>
          </div>
        </div>

        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-lg-6 col-md-12">
            <form id="contactForm" noValidate onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      id="sendernames"
                      name="sendernames"
                      className="form-control"
                      required
                      data-error="Please enter your name"
                      placeholder="Name"
                    />

                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <input
                      type="email"
                      id="senderemail"
                      name="senderemail"
                      className="form-control"
                      required
                      data-error="Please enter your email"
                      placeholder="Email"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="senderphone"
                      name="senderphone"
                      className="form-control"
                      placeholder="Phone"
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      className="form-control"
                      id="message"
                      cols="30"
                      rows="5"
                      required
                      data-error="Write your message"
                      placeholder="Type a message here..."
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <button type="submit" className="btn coder-btn-outlined">
                    Send Message
                  </button>

                  <div id="msgSubmit" className="h3 text-center hidden"></div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactBody;
