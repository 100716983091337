import React from "react";
import image from "../../assets/img/animations/frontend.gif";

export default function About() {
  return (
    <section className="about-area ptb-150 bg-f7fafd">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={image} alt="" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="section-title">
                <h2 className="pb-3">About Us</h2>
                <p>
                  Capacity Building is a program aiming at preparing the next
                  generation of 6 years and older developers through trainings
                  to introduce young people to programming skills and create a
                  specialized pool of “A level” developers by upskilling already
                  existing programmers in different languages.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="about-inner-area">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="about-text">
                <h3>Our Vision</h3>
                <p>
                  Our vision is to prepare the next generation of developers
                  through trainings. We introduce young people to programming
                  skills and create a specialized pool of “A level” developers
                  by upskilling already existing programmers in different
                  languages.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 offset-lg-0 offset-md-3">
              <div className="about-text">
                <h3>Who we are</h3>
                <p>
                  We are a pan African firm dedicated to crashing barriers of
                  Africa’s technological progress. We offer services that
                  provide high-tech tools such as apps. We also aim to expand
                  the community of developers, support the growth of upcoming
                  businesses, and link investors to companies in need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
