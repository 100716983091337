import React from "react";
import { Link } from "react-router-dom";
import { CheckCircle, Twitter, Instagram, Linkedin } from "react-feather";
import logo from "../../assets/img/logo.png";

const Footer = () => {
  return (
    <footer className="footer-area bg-f7fafd">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
              <p>
                Our aim is preparing young generation developers through
                trainings and upskilling already existing programmers in
                different languages.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget pl-5">
              <h3>Links</h3>
              <ul className="list">
                <li>
                  <Link to="/about">
                    <CheckCircle /> About Us
                  </Link>
                </li>
                <li>
                  <Link to="/programs">
                    <CheckCircle /> Programs
                  </Link>
                </li>
                <li>
                  <Link to="/initiatives">
                    <CheckCircle /> Initiatives
                  </Link>
                </li>
                <li>
                  <Link to="/faqs">
                    <CheckCircle /> FAQ's
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <CheckCircle /> Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <h3>We offer you more</h3>
              <ul className="list">
                <li>
                  <a
                    href="https://amgroup.africa/service/Offshore%20Outsourcing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CheckCircle /> Offshore Outsourcing
                  </a>
                </li>
                <li>
                  <a
                    href="https://amgroup.africa/service/Innovation,%20Startup%20&%20Entrepreneurship"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CheckCircle /> Innovation, Startup & Entrepreneurship
                  </a>
                </li>
                <li>
                  <a
                    href="https://amgroup.africa/service/Investment"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CheckCircle /> Investment
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <h3>Follow us</h3>

              <ul className="social-links">
                <li>
                  <a
                    href="https://twitter.com/amutangana"
                    target="_blank"
                    rel="noreferrer"
                    className="twitter"
                  >
                    <Twitter />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/amutangana/"
                    target="_blank"
                    rel="noreferrer"
                    className="instagram"
                  >
                    <Instagram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/amutangana"
                    target="_blank"
                    rel="noreferrer"
                    className="linkedin"
                  >
                    <Linkedin />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="copyright-area">
              <p>
                Copyright {new Date().getFullYear()} Coder | Developed by{" "}
                <a
                  href="https://tunez-patrick.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Patrick
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
