import { programsActionTypes } from "../actionTypes";

const initialState = {
  loading: false,
  programs: [],
  message: null,
};

export const getPrograms = (prevState = initialState, { type, payload }) => {
  switch (type) {
    case programsActionTypes.PROGRAMS_REQUEST:
      return {
        ...prevState,
        loading: true,
      };

    case programsActionTypes.PROGRAMS_SUCCESS:
      return {
        ...prevState,
        loading: false,
        programs: payload,
      };

    case programsActionTypes.PROGRAMS_FAIL:
      return {
        ...prevState,
        loading: false,
        message: payload,
      };

    default:
      return prevState;
  }
};
