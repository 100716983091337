import React from "react";
import { Link } from "react-router-dom";
import Typical from "react-typical";

const steps = ["It's free!", 1000, "It's fun 👌", 1000];

const MainBanner = () => {
  return (
    <section className="coder-main-banner bg-f7fafd">
      <div className="container">
        <div className="coder-banner-content">
          <h2>Learn coding with us.</h2>
          <Typical wrapper="h3" steps={steps} loop={Infinity} />
          <p>
            Become an expert in programming languages and computing concepts
            with us. Get the training you need for a career in hardware and
            software development. Like thousands, learn how to code, computer
            hardware, networking and much more by joining us.
          </p>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6">
              <Link to="/programs">
                <button className="btn coder-btn">Choose a course</button>
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <Link to="/donate">
                <button className="btn coder-btn-outlined">
                  Help us progress!
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainBanner;
