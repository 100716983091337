import React from "react";
import { ArrowRight } from "react-feather";
import image from "../../assets/img/initiatives/coder-one.svg";
import image2 from "../../assets/img/initiatives/coder-two.svg";

const InitiativesComponent = () => {
  return (
    <section className="blog-area ptb-150 bg-f7fafd">
      <div className="container">
        <div className="section-title">
          <h2>Initiatives</h2>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="single-blog-post">
              <div className="blog-image">
                <a
                  href="https://amgroup.africa/initiative/SCHOOL%20OF%20CODING"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={image} alt="" />
                </a>
              </div>

              <div className="blog-post-content">
                <h3>
                  <a
                    href="https://amgroup.africa/initiative/SCHOOL%20OF%20CODING"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    SCHOOL OF CODING
                  </a>
                </h3>

                <p>
                  In partnership with The American Refugee Committee, This
                  program aims to provide a unique, extensive program that will
                  help equip and groom talented creative refugee programmers to
                  provide them with necessary tools needed to make them ...
                </p>
                <a
                  href="https://amgroup.africa/initiative/SCHOOL%20OF%20CODING"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="read-more-btn"
                >
                  Read More <ArrowRight />{" "}
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-blog-post">
              <div className="blog-image">
                <a
                  href="https://amgroup.africa/initiative/FUTURE%20CODERS"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={image2} alt="" />
                </a>
              </div>

              <div className="blog-post-content">
                <h3>
                  <a
                    href="https://amgroup.africa/initiative/FUTURE%20CODERS"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    FUTURE CODERS
                  </a>
                </h3>

                <p>
                  Future coders is a program of equipping young students with
                  extra knowledge outside school work in coding/programming.
                  Through this program, the students aged between 8-15 are
                  introduced in the fundamentals of coding as well as ...
                </p>
                <a
                  href="https://amgroup.africa/initiative/FUTURE%20CODERS"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="read-more-btn"
                >
                  Read More <ArrowRight />{" "}
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-blog-post">
              <div className="blog-image">
                <a
                  href="https://amgroup.africa/initiative/FUTURE%20CODERS"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={image2} alt="" />
                </a>
              </div>

              <div className="blog-post-content">
                <h3>
                  <a
                    href="https://amgroup.africa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    DIGITAL TRAINING FOR YOUNG GIRLS
                  </a>
                </h3>

                <p>
                  A program that gives opportunities to young women by offering
                  digital training to adolescent girls and connecting them to
                  internships where they get to develop digital apps to address
                  market barriers.
                </p>
                <h6>Objectives</h6>
                <p>
                  <ul>
                    <li>
                      To empower girls by equipping them for careers in science.
                      Technology, and innovation in Africa
                    </li>
                    <li>To mentor them so they gain in confidence</li>
                    <li>
                      To support them on their way to their first profesionnal
                      experience.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InitiativesComponent;
